import { useState, useEffect, useCallback } from "react";
import { getAgents, deleteAgent, createAgent, getUploadUrl } from "./client";
import './Agents.css';

export const Agents = () => {
    const [agents, setAgents] = useState([]);
    const [lastAgentId, setLastAgentId] = useState('');
    const [direction, setDirection] = useState('Ascending');
    const [agentId, setAgentId] = useState('');
    const [agentName, setAgentName] = useState('');
    const [agentType, setAgentType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCodeEditOpen, setIsCodeEditOpen] = useState(false);
    const [agentRequirements, setAgentRequirements] = useState('');
    const [agentCode, setAgentCode] = useState('');

    const fetchAgents = useCallback(async () => {
        try {
            const response = await getAgents(lastAgentId, direction);
            if(response.Agents !== undefined) {
                setAgents(response.Agents);
            }
        } catch (error) {
            console.error("Error fetching agents:", error);
        }
    }, [lastAgentId, direction]);

    useEffect(() => {
        fetchAgents();
    }, [fetchAgents]);

    const handleDelete = async (id) => {
        try {
            await deleteAgent(id);
            setAgents(agents.filter(agent => agent.id !== id));
            fetchAgents();
        } catch (error) {
            console.error("Error deleting agent:", error);
        }
    };

    const handleAddCode = (id) => {
       setIsModalOpen(false);
        setIsCodeEditOpen(true);
        setAgentId(id);
        setAgentCode('def main():\n    print("Hello, World!")\n\nif __name__ == "__main__":\n    main()');
        setAgentRequirements('');
    };

    const handleCodeSubmit = async () => {
        const uploadUrls = await getUploadUrl(agentId, 'python');

        const requirementUrl = uploadUrls.RequirementsUploadUrl;
        const agentUrl = uploadUrls.AgentUploadUrl;

        await fetch(requirementUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: agentRequirements
        });

        await fetch(agentUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'text/x-python'
            },
            body: agentCode
        });


        setIsCodeEditOpen(false);
        setAgentCode('def main():\n    print("Hello, World!")\n\nif __name__ == "__main__":\n    main()');
        setAgentRequirements('');
    };

    const handleEdit = (id, name, type) => {
        setAgentId(id);
        setAgentName(name);
        setAgentType(type);
        setIsModalOpen(true);
        setIsCodeEditOpen(false);
    };

    const handleCreate = async () => {
        try {
            const newAgent = await createAgent(agentId, agentName, agentType );
            setAgents([...agents, newAgent]);
            setAgentId('');
            setAgentName('');
            setAgentType('');
            setIsModalOpen(false);
            fetchAgents();
        } catch (error) {
            console.error("Error creating agent:", error);
        }
    };

    const handleNextPage = () => {
        const lastAgent = agents[agents.length - 1];
        setLastAgentId(lastAgent ? lastAgent.id : null);
        setDirection('Ascending');
    };

    const handlePreviousPage = () => {
        const firstAgent = agents[0];
        setLastAgentId(firstAgent ? firstAgent.id : null);
        setDirection('Descending');
    };

    return (
        <div className="agents-container">
            <h1>Agents</h1>
            <button onClick={() => setIsModalOpen(true)}>Create Agent</button>
            <table className="agents-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {agents.map(agent => (
                        <tr key={agent.Id}>
                            <td>{agent.Id}</td>
                            <td>{agent.Name}</td>
                            <td>{agent.AgentType}</td>
                            <td>
                                <button onClick={() => handleAddCode(agent.Id)}>Add Code</button>
                                <button onClick={() => handleEdit(agent.Id, agent.Name, agent.AgentType)}>Edit</button>
                                <button onClick={() => handleDelete(agent.Id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={handlePreviousPage}>Previous</button>
                <button onClick={handleNextPage}>Next</button>
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <h2>Create / Update Agent</h2>
                        <input
                            type="text"
                            placeholder="Agent Name"
                            value={agentName}
                            onChange={(e) => setAgentName(e.target.value)}
                        />

                        <input 
                            type="option"
                            placeholder="Agent Type"
                            value={agentType}
                            onChange={(e) => setAgentType(e.target.value)}
                        />
                        <button onClick={handleCreate}>Submit</button>
                    </div>
                </div>
            )}
            {isCodeEditOpen && (
                <div className="codeedit">
                    <div className="codeedit-content">
                        <span className="close" onClick={() => setIsCodeEditOpen(false)}>&times;</span>
                        <h2>Edit Agent Code</h2>
                        <div>
                        <h3>requirements.txt</h3>
                        <textarea rows={20} cols={80}
                            type="text"
                            placeholder="Agent Requirements"
                            value={agentRequirements}
                            onChange={(e) => setAgentRequirements(e.target.value)}
                        />
                        </div>
                        <div>
                        <h3>agent.py</h3>
                        <textarea rows={20} cols={80}
                            type="text"
                            placeholder="Agent Code"
                            value={agentCode}
                            onChange={(e) => setAgentCode(e.target.value)}
                        />
                        </div>
                        <button onClick={handleCodeSubmit}>Submit</button>
                    </div>
                </div>
            )}
        </div>
    );
};