import { auth } from "./firebase";

const baseUrl = "https://api.aigamesarena.com";

const userEndPoint = "/User/";
const agentEndPoint = '/Agent/';
const agentsEndPoint = '/Agents/';
const getUploadUrlEndPoint = '/GetFileUploadURL/';

// post request to create / update a user

export const createUser = async (name, dob) => {
    const user = {
        Name : name,
        DateOfBirth : dob,
        Id : auth.currentUser.uid
    }
    const response = await fetch(baseUrl + userEndPoint, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        "AccessToken": await auth.currentUser.getIdToken(),
        },
        body: JSON.stringify(user),
    });
    return response.json();
    };

// get request to get a user by id
export const getUser = async () => {
    const response = await fetch(baseUrl + userEndPoint + auth.currentUser.uid, {
        method: "GET",
        headers: {
        "Content-Type": "application/json",
        "AccessToken": await auth.currentUser.getIdToken(),
        },
    });
    return response.json();
};

// delete request to delete a user by id
export const deleteUser = async (id) => {
    const response = await fetch(baseUrl + userEndPoint + id, {
        method: "DELETE",
        headers: {
        "Content-Type": "application/json",
        "AccessToken": await auth.currentUser.getIdToken(),
        },
    });
    return response.json();
};

export const createAgent = async (agentId, name, agentType) => {
    const agent = {
        Id: agentId,
        Name: name,
        AgentType: agentType
    };
    const response = await fetch(baseUrl + agentEndPoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": await auth.currentUser.getIdToken(),
        },
        body: JSON.stringify(agent),
    });
    return response.json();
};

// get request to retrieve all agents
export const getAgent = async (id) => {
    const response = await fetch(baseUrl + agentEndPoint + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": await auth.currentUser.getIdToken(),
        },
    });
    return response.json();
};

// delete request to delete an agent by id
export const deleteAgent = async (id) => {
    const response = await fetch(baseUrl + agentEndPoint + id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": await auth.currentUser.getIdToken(),
        },
    });
    return response.json();
};

export const getAgents = async (lastAgent, direction) => {
    const response = await fetch(baseUrl + agentsEndPoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": await auth.currentUser.getIdToken(),
        },
        body: JSON.stringify({AgentId : lastAgent, Direction : direction})
    });
    return response.json();
};

export const getUploadUrl = async (agentId, fileType) => {
    const response = await fetch(baseUrl + getUploadUrlEndPoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": await auth.currentUser.getIdToken(),
        },
        body: JSON.stringify({AgentId : agentId, FileFormat : fileType})
    });
    return response.json();
};