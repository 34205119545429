import React from 'react';
import {auth} from './firebase';
import {signOut} from './auth_google_signin_popup';
import { useState } from 'react';
import { ProfileForm } from './profile';
import { Agents } from './agents';
import { Game } from './game';

const Mainpage = () => {
    const [windowState, setWindowState] = useState('home');

    // Signout function
    const logout = () => {
        signOut(auth);
    }
    
    return (
        <div>
            <nav style={styles.navbar}>
                <div style={styles.navLinks}>
                    <button style={styles.navButton} onClick={() => setWindowState('home')}>Home</button>
                    <button style={styles.navButton} onClick={() => setWindowState('leaderboard')}>Leaderboard</button>
                    <button style={styles.navButton} onClick={() => setWindowState('agents')}>Agents</button>
                    <button style={styles.navButton} onClick={() => setWindowState('game')}>Game</button>
                    <button style={styles.navButton} onClick={() => setWindowState('profile')}>Profile</button>
                </div>
                <div style={styles.profileContainer}>
                    <img src={auth.currentUser.photoURL} alt="Profile" style={styles.profilePic}/> 
                    <span>Welcome {auth.currentUser.displayName}</span>
                    <button style={styles.signOutButton} onClick={logout}>Sign Out</button>
                </div>
            </nav>
            <div style={styles.mainWindow}>
                {windowState === 'home' && <div>Home Content</div>}
                {windowState === 'leaderboard' && <div>Leaderboard Content</div>}
                {windowState === 'agents' && <Agents/>}
                {windowState === 'profile' && <ProfileForm/>}
                {windowState === 'game' && <Game/>}
            </div>
        </div>
    );
}

const styles = {
    navbar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 20px',
        backgroundColor: '#343a40',
        color: '#fff',
        alignItems: 'center',
    },
    navLinks: {
        display: 'flex',
    },
    navButton: {
        marginRight: '10px',
        padding: '10px 15px',
        backgroundColor: '#495057',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    profileContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    profilePic: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: '10px',
    },
    signOutButton: {
        padding: '10px 15px',
        backgroundColor: '#dc3545',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginLeft: '10px',
    },
    mainWindow: {
        padding: '20px',
        fontSize: '18px',
    }
};

export default Mainpage;