// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD07fe4rGMdIVWqEgmsnM1t5cTiP2pQHM4",
  authDomain: "p1-gp-440805.firebaseapp.com",
  projectId: "p1-gp-440805",
  storageBucket: "p1-gp-440805.firebasestorage.app",
  messagingSenderId: "920831765023",
  appId: "1:920831765023:web:b8c0c48b3544525836832a",
  measurementId: "G-LGQJ1B37M2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Auth and Google Auth Provider
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { app, analytics, auth, provider };