import React, { useState, useEffect } from 'react';
import { createUser, getUser } from './client';

export const ProfileForm = () => {
    const [name, setName] = useState('');
    const [dob, setDob] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await getUser();
                
                setName(response.Name);
                setDob(response.DateOfBirth);

            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await createUser( name, dob );
            if (response.status === 200) {
                console.log('User created successfully');
            } else {
                console.error('Error creating user:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    const formStyle = {
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9'
    };

    const inputStyle = {
        width: '90%',
        padding: '10px',
        margin: '10px 0',
        border: '1px solid #ccc',
        borderRadius: '5px'
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold'
    };

    const buttonStyle = {
        width: '100%',
        padding: '10px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <div>
                <label htmlFor="name" style={labelStyle}>Name:</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    style={inputStyle}
                />
            </div>
            <div>
                <label htmlFor="dob" style={labelStyle}>Date of Birth:</label>
                <input
                    type="date"
                    id="dob"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                    style={inputStyle}
                />
            </div>
            <button type="submit" style={buttonStyle}>Submit</button>
        </form>
    );
};