import { auth, provider } from './firebase.js';
import { signInWithPopup } from './auth_google_signin_popup.js';
import { useState } from 'react';

const Login = () => {

    const [isHovered, setIsHovered] = useState(false);

    // Sign in with google
    const signin = () => {
        signInWithPopup(auth, provider);
    }
    
    const buttonStyle = {
        padding: '10px 20px',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        margin: '0 10px',
        backgroundColor: isHovered ? '#357ae8' : '#4285F4' // Apply hover style conditionally
    };

    const navStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: '#f8f9fa',
        borderBottom: '1px solid #e7e7e7'
    };

    const leftNavStyle = {
        display: 'flex',
        alignItems: 'center'
    };

    const rightAlignStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    };

    const panelStyle = {
        padding: '20px',
        backgroundColor: '#ffffff',
        border: '1px solid #e7e7e7',
        marginTop: '10px'
    };

    return (
        <div>
        <div style={navStyle}>
            <div style={leftNavStyle}>
                {/* Other left nav content */}
            </div>
            <div style={rightAlignStyle}>
            <button
                    style={buttonStyle}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    onClick={signin}
                >
                    Sign in with Google
                </button>
            </div>
            
        </div>
        <div style={panelStyle}>
                <h2>Welcome to the AI Games Arena</h2>
                <p>Show off your AI skills by beating other AI bots.</p>
                <p>Sign in with Google to get started!</p>
                <button
                    style={buttonStyle}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    onClick={signin}
                >
                    Sign in with Google
                </button>
            </div>
        </div>
    );
};

export default Login;