import React, { useState, useEffect } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './game.css'; // Make sure to create and import a CSS file for additional styling
import { auth } from './firebase';
import AgentFile from './Pong/Agent.py';
import RequirementFile from './Pong/requirements.txt';

export const Game = () => {
    const [chosenGame, setChosenGame] = useState('pong');
    const [gameCode, setGameCode] = useState('');
    const [requirements, setRequirements] = useState('');

    useEffect(() => {
        fetch(AgentFile)
        .then(response => response.text())
        .then(async text => {
            // find and replace accessToken = '' with accessToken = auth.currentUser.accessToken
            text = text.replace("accessToken = ''", `accessToken = '${await auth.currentUser.getIdToken()}'`);
            setGameCode(text)
        })
        .catch(error => console.error('Error loading text file:', error));
        fetch(RequirementFile)
        .then(response => response.text())
        .then(text => setRequirements(text))
        .catch(error => console.error('Error loading text file:', error));
    }, [chosenGame]);

    return (
        <div className="game-container">
            <h1>Game</h1>
            <div>Choose a Game!</div>
            <select value={chosenGame} onChange={(e) => setChosenGame(e.target.value)}>
                <option value="pong">Pong</option>
            </select>
            <div className="instructions">
                <h1>Instructions</h1>
                <div>
                    <p>To install python go to <a href='https://www.python.org/downloads/'>Download python</a></p>
                    <p>Run the following to create a virtual environment</p>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {'python -m venv myenv'}
                    </SyntaxHighlighter>
                    <button onClick={() => navigator.clipboard.writeText('python -m venv myenv')}>Copy Command</button>
                    <p>Run the following command to install the dependencies</p>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {'pip install -r requirements.txt'}
                    </SyntaxHighlighter>
                    <button onClick={() => navigator.clipboard.writeText('pip install -r requirements.txt')}>Copy Command</button>
                    <p>Run the following to run the code</p>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {'python Agent.py'}
                    </SyntaxHighlighter>
                    <button onClick={() => navigator.clipboard.writeText('python Agent.py')}>Copy Command</button>
                    <h4>Agent.py</h4>
                    <p>Write your agent code in the Agent.py file look at the class PongAgent</p>
                    <SyntaxHighlighter language="python" style={docco}>
                        {gameCode}
                    </SyntaxHighlighter>
                    <button onClick={() => navigator.clipboard.writeText(gameCode)}>Copy Code</button>
                    <h4>requirements.txt has the dependencies</h4>
                    <p>Write the requirements for your agent in the requirements.txt file</p>
                    <SyntaxHighlighter language="text" style={docco}>
                        {requirements}
                    </SyntaxHighlighter>
                    <button onClick={() => navigator.clipboard.writeText(requirements)}>Copy Requirements</button>
                </div>
            </div>
        </div>
    );
};

